<template>
  <div>
    <config-alerts-folios ref="ConfigAlertsFolios"/>
    <config-alerts-certificate ref="ConfigAlertsCertificate"/>
  </div>
</template>

<script>

export default {
  components: {
    ConfigAlertsFolios: () => import('./components/FoliosConfigs'),
    ConfigAlertsCertificate: () => import('./components/CertificateConfigs')
  },
}
</script>



